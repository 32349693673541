input:not([type="checkbox"]) {
  height: $btn-height;
  padding: 0 20px;
  font-size: 0.875rem;
}
input[type="checkbox"] {
  width: 15px;
  height: 15px;
}
.mc-dropdown {
  width: 185px;
  height: 40px;
  border-radius: 30px;
  button {
    width: 100%;
    height: 100%;
    border-radius: 30px;
    text-align: center;
    &.active, &:hover, &:focus {
      background-color: transparent !important;
      color: $mc-red;
      font-weight: 700;
    }
  }
  .dropdown-menu {
    width: 185px;
    margin: 0;
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
    box-shadow: 0 12px 15px #00000033;
    transform: translate(0px, 40px) !important;
  }
  &.show {
    box-shadow: 0px 15px 15px #00000033 !important;
    .dropdown-toggle {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      &:hover, &:focus {
        background-color: $mc-white !important;
      }
    }
  }
}
.mc-password-field {
  i.input-group-text {
    position: absolute;
    right: 0;
    height: 100%;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    padding: 0 20px;
    cursor: pointer;
    z-index: 10;
  }
}
label {
  margin-bottom: 5px;
}
