@import url("https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap");

body {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: $mc-black;
}
h1 {
  font-family: "Yeseva One", cursive;
  font-weight: 400;
  font-size: 1.875rem;
  line-height: 1.13;
  overflow-wrap: break-word;
}
h2 {
  font-family: "Yeseva One", cursive;
  font-weight: 400;
  font-size: 1.56rem;
  line-height: 1.36;
  overflow-wrap: break-word;
}
h3 {
  font-size: 1.31rem;
  font-weight: 700;
  line-height: 1.19;
  overflow-wrap: break-word;
}
h4 {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.22;
  overflow-wrap: break-word;
}
.h1_title {
  font-family: "Yeseva One", cursive;
  font-weight: 400;
  font-size: 1.875rem;
  line-height: 1.13;
}
p,
.par_1 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.18;
  overflow-wrap: break-word;
}
.par_2 {
  font-size: 0.875rem;
  line-height: 1.21rem;
  overflow-wrap: break-word;
}
.fz-25 {
  font-size: 1.56rem;
  line-height: 1.125;
}
.fz-40 {
  font-size: 2.5rem;
  line-height: 1.125;
}
.fz-60 {
  font-size: 3.75rem;
  line-height: 1;
}
a {
  color: $mc-black;
  text-decoration: none;
  &:hover {
    color: $mc-black;
    text-decoration: none;
  }
}
.fw-regular {
  font-weight: 400 !important;
}
.fw-semibold {
  font-weight: 600 !important;
}
.font-family-montserrat {
  font-family: "Montserrat" !important;
}

@media (max-width: 768px) {
  h1 {
    font-size: 1.56rem;
    line-height: 1.08;
  }
  h4 {
    font-size: 1rem;
    line-height: 1.19;
  }
  p,
  .par_1 {
    font-size: 0.75rem;
    line-height: 1.42;
  }
  .par_2 {
    font-size: 0.75rem;
    line-height: 1.42;
  }
  .fs-sm-18 {
    font-size: 1.125rem;
    line-height: 1.22;
  }
  .fs-sm-21 {
    font-size: 1.31rem;
    line-height: 1.19;
  }
}