.gap-25 {
  gap: var(--gap-25);
}
.gap-50 {
  gap: var(--gap-50);
}
.border-3 {
  border-width: 3px !important;
}

@media (max-width: 768px) {
  .sm-border-bottom {
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .no-border-mobile {
    border: 0 !important;
  }
  .position-sm-absolute {
    position: absolute !important;
  }

  .text-sm-left-c {
    text-align: left !important;
  }

  .text-sm-right-c {
    text-align: right !important;
  }
}

@media (min-width: 768px) {
  .py-md-5 {
      padding-top: 5rem !important;
      padding-bottom: 4rem !important;
  }
  .mb-md-5 {
      margin-bottom: 2.75rem !important;
  }
  .no-border-desk {
    border: 0 !important;
  }
}
