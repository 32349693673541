// Colors
$mc-blue: #152C69;
$mc-red: #DB230B;
$mc-green: #7AAE4D;
$mc-orange: #E98400;
$mc-white: #ffffff;
$mc-black: #333333;
$mc-gray: #666666;
$mc-light-gray: #D8D8D8;
$theme-colors: (
  "primary": $mc-blue,
  "secondary": $mc-red,
  "blue": $mc-blue,
  "red": $mc-red,
  "green": $mc-green,
  "orange": $mc-orange,
  "white": $mc-white,
  "black": $mc-black,
  "gray": $mc-gray,
  "light-gray": $mc-light-gray,
  "success": $mc-green,
  "danger": $mc-red,
  "warning": #cb9020,
  "info": $mc-gray,
  "facebook": #2E4E8A
);

// Layout
$container-max-widths: (
  xxl: 1440px
);
$grid-breakpoints: (
  xs: 0,
  sm: 300px,
  md: 768px,
  lg: 1024px
);

// Typography
$headings-margin-bottom: 0;
$paragraph-margin-bottom: 0;

// Buttons
$btn-height: 48px;
$btn-border-radius: 3px;

// Forms
$input-border-color: $mc-gray;
$input-border-radius: 3px;
$input-border-color: $mc-gray;
$input-height: $btn-height;
$form-check-input-border: 1px solid $mc-gray;

$border-radius: 50px;
$border-color: $mc-light-gray;
$box-shadow: 0px 4px 15px #00000033;

:root {
  --gap-25: 25px;
  --gap-30: 30px;
  --gap-50: 50px;

  --primary-footer-color: #152C69;
  --primary-light-color: #666;
  --content-wrapper-width: 1440px;
  --header-background: #fff;
  --footer-copyright-height: 50px;
  --footer-content-height: 320px;


  --header-menu-height: 70px;
  --diameter: 1;
  --offline-notice-height: 0px;
  --offline-notice-display: none;
  --header-nav-height: 90px;
  --header-top-menu-height: 20px;
  --image-logo-width: 171px;
  --header-height: calc(
    var(--header-nav-height) + var(--header-top-menu-height)
  );
  --header-total-height: calc(
    var(--header-height) + var(--offline-notice-height) +
    env(safe-area-inset-top) + 20px
  );
}

@media screen and (max-width: 768px) {
  :root {
    --header-nav-height: 104px;
    --header-height: var(--header-nav-height);
    --header-total-height: var(--header-nav-height);
    --header-menu-height: 70px;
    --image-logo-width: 130px;
  }
}
