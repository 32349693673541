.btn {
  width: 268px;
  text-transform: uppercase;
  font-size: 0.875rem;
  line-height: $btn-height;
  font-weight: 700;
  padding: 0 10px;
  border: none;
  margin-bottom: 15px;
  border-radius: 50px;
  &:focus {
    box-shadow: none;
  }
}
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    &,
    &:hover {
      color: $mc-white;
    }
  }
}
.btn-link {
  color: $mc-black;
  font-weight: 600;
  text-decoration: none;
  &:hover {
    color: $mc-red;
  }
}
.btn:disabled {
  opacity: 1;
  background-color: $mc-light-gray;
}

.btn-blue-reverse {
  color: #152C69;
  background-color: #fff;
  border: 2px solid #152C69;
  height: 48px;
  &:hover {
    color: #152C69;
    background-color: #fff;
    border: 2px solid #152C69;
  }
}

.btn-grey {
  color: #333;
  background-color: #fff;
  border: 1px solid #A8A8A8;
  height: 48px;
  font-weight: normal;
  &:hover {
    color: #152C69;
    background-color: #fff;
    border: 2px solid #152C69;
    font-weight: 700;
  }
}

@media (max-width: 768px) {
  .btn {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .btn-link {
    &:hover {
      cursor: pointer;
    }
  }
}
