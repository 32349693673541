.labelServices {
  text-align: left;
  font: normal normal normal 12px/15px Montserrat;
  color: #666666;
  padding-top: 0.1rem !important;
  text-decoration: underline;
  cursor: pointer;

  a {
    color: #666;
  }

  @media  screen and (max-width:768px) {
      font-size: 0.6rem;
  }
}

.icon-modal {
  text-align: center;
  font-size: 1.7rem;
  margin-bottom: 0.5rem;
  color: red;
}

.bi-exclamation-triangle {
  color: #db230b;
  font-size: 1.8rem;
}

.pdf-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.1607843137);
  border-radius: 18px;
  padding: 15px 20px;
  align-items: center;
  p {
    font: normal normal normal 14px/18px Montserrat;
    &:first-child {
      color: #db230b;
    }
    a {
      text-decoration: underline;
      color: #00a4d6;
    }
  }
  span {
    font: normal normal normal 14px/18px Montserrat;
    &:first-child {
      color: #db230b;
    }
    &:last-child a {
      text-decoration: underline;
      color: #00a4d6;
      cursor: pointer;
    }
  }
}

.inlineFlex {
  display: inline-flex;
  align-items: center;
}

.icon-sm {
  font-size: smaller;
}

.icon-lg {
  font-size: large;
}
